const phoneRegex = /^01\d{9}$/
const codeRegex = /^\d{6}$/
const emailRegex = /^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
const usernameRegex = /^[a-zA-Z][a-zA-Z\-_0-9]*$/
const usernameLoginRegex = /^([a-zA-Z][a-zA-Z\-_0-9]{0,31}|0?[896]\d{8}|(5|6|8|9)\d{7}|[a-zA-Z0-9_\-.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+)$/
const idcardRegex = /^([a-zA-Z][12][0-9]{8}|((\s?[A-Za-z])|([A-Za-z]{2}))\d{6}(([0−9aA])|([0-9aA]))|[1|5|7][0-9]{6}\([0-9Aa]\)|[1-8]\d{12})$/

const passwordRegex = /^[\u0020-\u007e]+$/


function phone (str) {
    if (str.length <= 2)
        return ('請輸入手機號碼')
    if (!phoneRegex.test(str))
        return ('手機號碼不正確，請重新輸入')
}

function code (str) {
    if (!str)
        return ('請輸入驗證碼')
    if (!codeRegex.test(str))
        return ('驗證碼為 6 位數字')
}

function email (str) {
    if (!str)
        return ('請輸入信箱')
    if (!emailRegex.test(str))
        return ('信箱格式有誤')
}

function username (str) {
    if (!str)
        return ('請輸入賬號')
    if (!usernameRegex.test(str))
        return ('自訂賬號需為英文字母和數字的組合，且第一個字元必須為英文，英文及數字必須為半形')
    if (str.length > 32)
        return ('自訂賬號長度最多為 32 字元')
}

function usernameLogin (str) {
    if (!str)
        return ('請輸入賬號')
    if (!usernameLoginRegex.test(str))
        return ('請輸入正確賬號')
}

function password (str) {
    if (!str)
        return ('請輸入密碼')
    if (str.length < 8)
        return ('密码至少要包含8位英文和数字')
    if (!passwordRegex.test(str))
        return ('密碼不能有特殊字元')
}

function passwordLogin (str) {
    if (!str)
        return ('請輸入密碼')
    if (!passwordRegex.test(str))
        return ('密碼不能有特殊字元')
}

function idcard (str) {
    if (!str)
        return ('請輸入身份證號')
    if (!idcardRegex.test(str))
        return ('身份證號格式有誤')
}

export default {
    phone,
    code,
    email,
    username,
    password,
    passwordLogin,
    idcard,
    usernameLogin,
}
