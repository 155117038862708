import JSEncrypt from 'jsencrypt';

const keyData = ('-----BEGIN PUBLIC KEY-----\n'
+ 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDOAL6DLscQT6nv28/DvKxkikTS\n'
+ 'cNxgtMpkRlSY/08X+fi2zSL+1dVW57PVyemcGyOlNfE2vW1idlZ3d2BffEGJPjLT\n'
+ 'FZtVg87QmR6FQDglEwD3/I/jG2PZc0XN5A/KyjvTaLdQU5Yyg+O2aCvg7FfodKPW\n'
+ 'jbrDFhrDQDYQcWfKWQIDAQAB\n'
+ '-----END PUBLIC KEY-----')
const encryptStr = new JSEncrypt();
encryptStr.setPublicKey(keyData);

export const encrypt = (msg) => {
    return encryptStr.encrypt(msg)
}
